onPageLoaded(function() {
  const activeTab = 0;
  let memory = 0;

  const getMedia = () => window.matchMedia('(min-width: 981px)').matches;

  let media = getMedia();
  window.addEventListener('resize', event => {
    rebuldFAQ(media = getMedia());
    if (media) setActiveTab(memory, false);
  });

  const $tabWrap = $('.tabs');
  const $items = $tabWrap.find('.tabs-toggle-item');
  const $titles = $tabWrap.find('.tabs-toggle-item__title');
  const $tabsContentBox = $tabWrap.find('.tabs-content');
  const $tabContents = $tabWrap.find('.tabs-content-box');

  const activeItemCN = 'tabs-toggle-item_active';
  const activeTabContentCN = 'tabs-content-box_active';

  rebuldFAQ(media);
  setActiveTab(activeTab, false);

  $titles.on('click', event => {
    const $target = $(event.currentTarget);
    const index = $target.parent().index();

    const self = $target[0] == $(`.${activeItemCN}`).find($titles)[0];
    memory = index;

    setActiveTab(index, self);
  });

  function setActiveTab(i, self) {
    $(`.${activeItemCN}`).removeClass(activeItemCN);
    $(`.${activeTabContentCN}`).removeClass(activeTabContentCN);

    if (!self || media) {
      $items.eq(i).addClass(activeItemCN);
      $tabContents.eq(i).addClass(activeTabContentCN);
    }
  }

  function rebuldFAQ(media) {
    if (media) $tabsContentBox.append($tabContents);
    else $items.each( (i, el) => $(el).append( $tabContents.eq(i) ) );
  }
});
